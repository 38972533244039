import React, { useMemo } from 'react'
import { hooks, I18n, CDNSVG } from '@front/volcanion'

import GeoUtils from '@front/volcanion/utils/geo'
import { TimeUtils, BillingUtils } from '@front/volcanion/utils'

import moment from 'moment'

import { OrderLink, DriverLink } from '@abra/elements'
import { TableCell, Box, Icon } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  const isB2B = !!row?.service
  const transport = row?.assigned_transport
  const orderStatus = hooks.useOrderStatus(row, { translated: true })
  const transportStatus = hooks.useTransportStatus(transport, { translated: true, load_type: row?.loads[0]?.type })
  const payementMethod = _.get(row, 'payment_type.name_translated')

  const etaValue = useMemo(() => {
    const eta_time = _.get(row, 'current_target.eta.time')
    if (!!eta_time)
      return `${moment.utc(eta_time).diff(moment.utc(), 'minutes')} min`
    else if (!!_.get(row, 'assigned_transport.info') && _.get(row, 'assigned_transport.status') === 'started') {
      const src_datetime = _.get(row, 'assigned_transport.info.src_datetime')
      if (moment.utc(src_datetime).isBefore(moment.utc()))
        return I18n.t('date.outdated')
      else return `${moment.utc(src_datetime).diff(moment.utc(), 'minutes')} min`
    }
    else return null
  }, [row])


  const { ORDER_LIST_SOURCE_FORMAT, ORDER_LIST_DESTINATION_FORMAT } = hooks.useRelayConstants(['ORDER_LIST_SOURCE_FORMAT', 'ORDER_LIST_DESTINATION_FORMAT',])

  const estimated_time = useMemo(() => {
    if (!row?.info?.src_datetime || !row?.info?.dest_datetime) return '-'
    else {
      const duration = moment(row?.info?.dest_datetime).diff(moment(row?.info?.src_datetime), 'seconds')
      const format = duration >= 3600 ? 'HH[h]mm[min]' : 'mm[min]'
      return TimeUtils.secondsToTime(duration, format)
    }
  }, [row?.info?.src_datetime, row?.info?.dest_datetime])

  const requestedAt = moment.utc(TimeUtils.getDetailsMomentFront(row?.requestedAt, row?.source)).local().format('DD/MM/YYYY HH:mm')
  const total = BillingUtils.getPaymentDisplay(row?.job?.payment, false, 1)
  return (
    <>
      <TableCell size='small' padding='none'>
        <CDNSVG sx={{ color: 'primary.main', fontSize: 18 }} name={!!isB2B ? 'account-b2b' : 'account-b2c'} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <OrderLink record_id={row?.order_id} fontSize={13} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <Box display='flex' alignItems='center' ml={1}>
          <Icon color='primary' fontSize={'small'}>
            {row?.schedule_type === 'planned' ? 'calendar_month' : 'download'}
          </Icon>
          <TextCell fontSize={13} value={requestedAt} />
        </Box>
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={row?.commercial_package?.name_translated} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={!!isB2B ? row?.service?.contract?.formula.name : row?.do?.commercial_formula?.name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={!!isB2B ? row?.service?.contract?.company?.name : row?.do?.customerinfo?.name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} textAlign='start' value={GeoUtils.getSpecificFormat(row?.source, ORDER_LIST_SOURCE_FORMAT || 'friendly')} />
        <TextCell fontSize={13} textAlign='start' value={GeoUtils.getSpecificFormat(row?.destination, ORDER_LIST_DESTINATION_FORMAT || 'friendly')} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={estimated_time} />
      </TableCell >
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} textAlign='start' value={_.get(row, 'loads.0.src_contact')} />
      </TableCell>
      <TableCell size='small' padding='none'>
        {!!row?.assigned_transport?.driver
          ? <DriverLink record_id={row?.assigned_transport?.driver} />
          : '-'
        }</TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={orderStatus} color={hooks.useOrderStatusColor(row)} />
        <TextCell fontSize={13} value={transportStatus} color={hooks.useTransportStatusColor(row?.assigned_transport)} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={row?.channel.name} /></TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={payementMethod} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={etaValue} />
      </TableCell>

      <TableCell size='small' padding='none'>
        <TextCell fontSize={13} value={total} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
